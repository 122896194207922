$(function() {
  if (location.pathname !== '/admin/interests') return;

  $(document).on('ajax:success', '.js-admin-interest-form', function(e) {
    const $this = $(this);
    const response = e.detail[0];
    const applicant_el = $(document).find(`.js-interest-${response.id}`)

    applicant_el.find('.js-ca-status-field')
      .text(response.translated_ca_status)

    applicant_el.find('.js-evaluation-field')
    .text(response.translated_evaluation)

    applicant_el.find('.js-job-id-token-field')
      .text(response.job_id_token)
    $this.parents('.modal').modal('hide')
  })
});

